import { render, staticRenderFns } from "./ComponentExposureChart.vue?vue&type=template&id=889ddca8&scoped=true"
import script from "./ComponentExposureChart.vue?vue&type=script&lang=js"
export * from "./ComponentExposureChart.vue?vue&type=script&lang=js"
import style0 from "./ComponentExposureChart.vue?vue&type=style&index=0&id=889ddca8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "889ddca8",
  null
  
)

export default component.exports